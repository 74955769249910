import React, { useEffect, useState } from 'react';
import { withRouter } from 'next/router';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { SavedSuccessIcon, SavedSuccessCheckIcon } from '../../utility/Icons';
import track from '../../../lib/track';
import combineStyles from '../../../styles/combineStyles';
import { buttonStyles } from '../../../styles/base';
import styles from '../../../styles/sass/components/SaveAndContinueDialog.module.scss';

export interface Props {
  router: any;
  classes: any;
  closeDialog: Function;
}

export const SaveAndContinueSuccessDialogContent: React.FC<Props> = ({
  router,
  classes,
  closeDialog,
}) => {
  const [tracked, setTracked] = useState<any>({});

  useEffect(() => {
    if (!tracked) {
      track.modalView('SaveAndContinueSuccessDialog Dialog');
      setTracked({ tracked: true });
    }
  }, []);

  const continueQuizHandler = () => {
    closeDialog();
  };

  const redirectToDashboard = () => {
    router.push('/dashboard');
    closeDialog();
  };

  return (
    <div className={styles.saveAndContinueDialogInner}>
      <div className={styles.dialogContainer}>
        <span className={styles.plumIcon}>
          <SavedSuccessIcon />
        </span>
        <span className={styles.checkIcon}>
          <SavedSuccessCheckIcon />
        </span>
      </div>
      <span className={styles.title}>Account created</span>
      <span className={styles.subtitle}>Your progress has been saved!</span>
      <div className={styles.buttonContainer}>
        <Button
          variant="contained"
          style={{ marginBottom: 24 }}
          className={classes.buttonPurpleSaveDialog}
          size="large"
          onClick={continueQuizHandler}
        >
          <span className={styles.buttonText}>
            Continue Personalization Quiz
          </span>
        </Button>
        <Button
          variant="contained"
          className={classes.buttonOutlineSaveDialog}
          size="large"
          onClick={redirectToDashboard}
        >
          <span className={styles.buttonText}>Take me to my Dashboard</span>
        </Button>
      </div>
    </div>
  );
};

const combinedStyles = combineStyles(buttonStyles, styles);

export default withRouter(
  withStyles(combinedStyles)(SaveAndContinueSuccessDialogContent),
);
