import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResponsiveDialog from '../../utility/ResponsiveDialog';
import SaveAndContinueSuccessDialogContent from './SaveAndContinueSuccessDialogContent';
import { isMobile } from '../../../lib/getDeviceSize';
import * as types from '../../../actions/actionTypes';

import styles from '../../../styles/sass/components/SaveAndContinueDialog.module.scss';

export interface Props {
  dispatch: any;
  open: boolean;
}

export const SaveAndContinueSuccessDialog: React.FC<Props> = props => {
  const handleClose = () => {
    props.dispatch({ type: types.CloseSaveAndContinueSuccessDialog });
  };

  return (
    <ResponsiveDialog
      mediumLargePaper={isMobile() ? false : true}
      mediumPaper={isMobile() ? true : false}
      onClose={handleClose}
      open={props.open}
      className={styles.saveAndContinueDialog}
      closeIconStyles={{
        width: '32',
        height: '32',
      }}
      closeButtonColor="rgba(85, 85, 85, 1)"
      buttonClassName={styles.saveAndContinueDialogCloseButton}
    >
      <SaveAndContinueSuccessDialogContent closeDialog={handleClose} />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.dialogs.saveAndContinueSuccessDialogOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveAndContinueSuccessDialog);
